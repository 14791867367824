import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  authToken = localStorage.getItem('authToken');
  headers = new HttpHeaders({ Authorization: `Bearer ${localStorage.getItem('authToken')}` });
  options = { headers: this.headers };

  constructor(private http: HttpClient, private router: Router, private snackBar: MatSnackBar) { }

  loginApi(endpoint, data): Observable<any> {
    // console.log('login request url --> ', endpoint, data);
    return this.http.post(endpoint, data).pipe(
      map((uresponse: Response) => {
        // console.log('loginApi res', uresponse);
        return uresponse;
      }), catchError(this.handleError<any>('Login')));
  }

  getApiWithoutAuth(endpoint): Observable<any> {
    // console.log('getApiWithoutAuth request url --> ', endpoint);
    return this.http.get(endpoint).pipe(
      map((uresponse: Response) => {
        // console.log('GET API res', uresponse);
        return uresponse;
      }), catchError(this.handleError<any>('Login')));
  }

  getApi(endpoint): Observable<any> {
    // this.headers = new HttpHeaders({'Authorization': `Bearer ${this.authToken}`});

    // console.log('get request url --> ', endpoint);
    return this.http.get(endpoint).pipe(
      map((uresponse: Response) => {
        // console.log('GET API res', uresponse);
        return uresponse;
      }), catchError(this.handleError<any>('Login'))
    );
  }

  postApi(endpoint, data): Observable<any> {
    // console.log('post request url --> ',endpoint, data);
    return this.http.post<any>(endpoint, data).pipe(
      map((uresponse: Response) => {
        // console.log('POST API res', uresponse);
        return uresponse;
      }), catchError(this.handleError<any>('Login'))
    );
  }

  putApi(endpoint, data): Observable<any> {
    // console.log('put request url --> ',endpoint, data);
    return this.http.put(endpoint, data).pipe(
      map((uresponse: Response) => {
        // console.log('PUT API response', uresponse);
        return uresponse;
      }), catchError(this.handleError<any>('Login'))
    );
  }

  deleteApi(endpoint): Observable<any> {
    // console.log('delete request url --> ',endpoint);
    return this.http.delete(endpoint).pipe(
      map((uresponse: Response) => {
        // console.log('DELETE API response', uresponse);
        return uresponse;
      }), catchError(this.handleError<any>('Login'))
    );
  }

  private handleError<T>(operation = 'operation', error?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error);
      // log to console instead
      // this.snackBar.open(error.error.message || error.error.error, '', { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center' });
      if (error.status === 401 && error.error.message === 'Unauthorized') {
        this.router.navigate(['/']);
      }
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty error.
      return of(error as T);
      // return (error.error);
    };
  }

}
