import {
  HttpErrorResponse, HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('pluginfile') === -1) {
      // Authentication token
      // const currentUser = this.loginService.currentUser;
      const tokenStored = this.authService.getToken();
      // Adding authorization if token is not null
      //  && tokenStored
      if (this.authService.isLoggednIn()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${tokenStored}`
          }
        });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('HttpInterceptor Response', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('HttpInterceptor Error', error);
        return throwError(error);
      })
    );
  }
}
