<div style="background: #141414;">
  <div style="height: 70px;">
      <ion-item style="
        --padding-start: 23px;
        height: 70px;
        min-height: 70px;
        --background: #141414;
      " lines="none">
          <span style="
          height: 70px;
          justify-content: flex-start;
          display: grid;
          align-content: center;
        " [ngStyle]="menuOpen && webView?{'width':'200px'}:{'width':'80px'}">
              <img *ngIf="!webView" src="assets/images/i3pl.logo.png" height="47" />
              <img *ngIf="!menuOpen && webView" src="assets/images/i3pl-whitelogo.png" height="55" />
              <img *ngIf="menuOpen && webView" src="./assets/images/i3pl.logo.png" height="60" />
          </span>
          <ion-button fill="clear" style="width: 60px; height: 70px; margin: 0px;" (click)="toggle()">
              <img src="assets/images/open-menu.png" style="height: 20px;" />
          </ion-button>
          <div slot="end" style="display: contents;">
              <ion-button fill="clear" class="notification-list"  style="width: 60px; height: 70px; margin: 0px;color: rgba(255, 255, 255, 0.6);">               
                  <i class="fe-bell noti-icon"></i>
                  <span class="badge badge-danger rounded-circle noti-icon-badge" style="right: 5px;">9</span>
              </ion-button>
              <ion-item  lines="none" class="user-item" color="tertiary">
                  <img style="height: 32px; border-radius: 100%; margin-inline-end: 10px;" slot="start"
                  [src]="userImage" />
                  <ion-label class="default-fontsize navbar-color">{{currentUser}}</ion-label>
                  <ion-icon slot="end" style="font-size: 12px; margin-inline-start: 10px;" name="chevron-down-outline">
                  </ion-icon>
              </ion-item>
          </div>
          
      </ion-item>
  </div>
  <ion-split-pane when="md" contentId="main" class="split-pane" [ngClass]="menuOpen?'split-pane':'split-pane-folded'">
      <ion-menu side="start" menuId="first" contentId="main" class="my-custom-menu" type="overlay"
          (ionWillClose)="menuOpen=false">
          <ion-content style="background: #141414;">
              <div style="padding: 20px 0;box-shadow: 0 0 35px 0 rgba(66, 72, 80, 0.15) !important;">
                  <div *ngFor="let menu of menuItems;let menuIndex=index;">
                      <ion-item class="menuItem" lines="none" color="tertiary"
                          (click)="menu.routerLink?goToPage(menu.routerLink):selectedMenuItem !== menuIndex?selectedMenuItem = menuIndex:selectedMenuItem = 99">
                          <!-- <ion-icon name="{{menu.menuIcon}}" slot="start" class="menuIcon"
                          [ngClass]="menuOpen?'menuIcon':'menuIconFold'"></ion-icon> -->
                          <i *ngIf="menu.menuName !== 'Certifications'" class="{{menu.menuIcon}}" slot="start"></i>
                         
                          <div style="margin-inline-end: 10px;" *ngIf="menu.menuName === 'Certifications'" slot="start">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award icon-dual"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                          </div>
                          
                          <ion-label class="menu-label">{{menu.menuName}}</ion-label>
                          <ion-icon *ngIf="selectedMenuItem !== menuIndex && menu.subMenu"
                              name="chevron-forward-outline" slot="end" style="font-size: 13px;"></ion-icon>
                          <ion-icon *ngIf="selectedMenuItem === menuIndex && menu.subMenu" name="chevron-down-outline"
                              slot="end" style="font-size: 13px;"></ion-icon>
                      </ion-item>
                      <div [ngClass]="selectedMenuItem === menuIndex && menu.subMenu?'showMenuClass':'hideMenuClass'">
                          <div *ngFor="let subMenu of menu.subMenu">
                              <ion-item class="menuItem" lines="none" color="tertiary" style="--padding-start: 55px;"
                                  (click)="openMenuItem(subMenu.routerLink)">
                                  <ion-label class="menu-label">{{subMenu.menuName}}</ion-label>
                              </ion-item>
                          </div>
                      </div>
                  </div>
              </div>
          </ion-content>
      </ion-menu>
      <div id="main">
          <div style="margin-top: 70px; height: 100%; ">
              <ng-content>
              </ng-content>
          </div>
      </div>
  </ion-split-pane>
</div>