import { Component, ElementRef, OnInit, QueryList, ViewChildren, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-projects-reports',
  templateUrl: './projects-reports.component.html',
  styleUrls: ['./projects-reports.component.scss'],
})
export class ProjectsReportsComponent implements OnInit {
  @ViewChildren('lineCanvas') lineCanvas: QueryList<ElementRef>;
  @Input() authChecker: boolean;

  reportsList: Array<any> = [];
  subSystem: string;
  component: string;
  testType: string;
  tester: string;
  barChart: Chart;
  breadcrumdData: Array<any>;
  projectName: string;
  projectDesc: string;
  locationId: string;
  locationName: string;
  projectId: string;
  pageLoader: boolean = true;
  subSystemFlag: boolean = false;

  constructor(
    private apis: DataService,
    private route: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe((param) => {
      this.locationName = param.get('locationName');
      this.locationId = param.get('locationId');
      this.projectId = param.get('projectId');
    });
    this.breadcrumdData = [
      { name: 'Home', route: '/home/location' },
      { name: this.locationName, route: '/home/project/' + this.locationId },
      { name: localStorage.getItem('projectName'), route: '#' },
    ];
    this.checkAuthType();

  }

  checkAuthType() {
    if (this.authChecker) {
      this.apis.getApi(environment.reports + this.projectId).subscribe(res => {
        this.getReports(res);
      });
    } else {
      this.apis.getApiWithoutAuth(environment.reportsPdf + this.projectId).subscribe(res => {
        this.getReports(res);
      });
    }
  }


  getReports(res) {
    res.message[0].subSystems = (res.message[0].subSystems).sort((a, b) => (a.subSystemName > b.subSystemName) ? 1 : -1);
    res.message[0].subSystems.forEach(element => {
      element.components = (element.components).sort((a, b) => (a.componentName > b.componentName) ? 1 : -1);
    });
    this.reportsList = [];
    this.projectName = res.message[0].name;
    this.breadcrumdData[2].name = this.projectName;
    this.projectDesc = res.message[0].description;
    let subsystemGroupData = {
      subSystem: res.message[0].name,
      pass: Math.ceil(res.message[0].totalPass),
      fail: Math.ceil(res.message[0].totalFail),
      pending: (100 - Math.ceil(res.message[0].totalPass) - Math.ceil(res.message[0].totalFail)),
      components: {
        componentsName: [],
        pass: [],
        fail: [],
        pending: []
      }
    }

    res.message[0].subSystems.forEach(subSystem => {
      let componentsName = [];
      let fail = [];
      let pass = [];
      let pending = [];
      subSystem.components.forEach(component => {
        componentsName.push(component.componentName);
        fail.push(Math.ceil(component.fail));
        pass.push(Math.ceil(component.pass));
        pending.push(100 - Math.ceil(component.pass) - Math.ceil(component.fail));
      });
      this.reportsList.push({
        subSystem: subSystem.subSystemName,
        pass: Math.ceil(subSystem.pass),
        fail: Math.ceil(subSystem.fail),
        pending: (100 - Math.ceil(subSystem.pass) - Math.ceil(subSystem.fail)),
        components: {
          componentsName: componentsName,
          pass: pass,
          fail: fail,
          pending: pending
        }
      });

      subsystemGroupData.components.componentsName.push(subSystem.subSystemName);
      subsystemGroupData.components.pass.push(Math.ceil(subSystem.pass));
      subsystemGroupData.components.fail.push(Math.ceil(subSystem.fail));
      subsystemGroupData.components.pending.push(100 - Math.ceil(subSystem.pass) - Math.ceil(subSystem.fail));
    });

    this.reportsList.splice(0, 0, subsystemGroupData);
    // console.log('After reportsList: ', this.reportsList);
    this.pageLoader = false;
    this.subSystemFlag = true;
    setTimeout(() => this.generateGraph(), 1000);
  }

  generateGraph() {
    // console.log('this.lineCanvas.toArray(): ', this.lineCanvas.toArray());
    this.lineCanvas.toArray().forEach((graph, index) => {
      // console.log('Graph: ', graph);
      this.barChart = new Chart(graph.nativeElement, {
        type: 'bar',
        data: {
          labels: this.reportsList[index].components.componentsName,
          datasets: [
            {
              label: 'Failed',
              data: this.reportsList[index].components.fail,
              fill: false, borderColor: '#DC5E5E', backgroundColor: '#DC5E5E', barThickness: 30
            },
            {
              label: 'Pass',
              data: this.reportsList[index].components.pass,
              fill: false, borderColor: '#1DBB99', backgroundColor: '#1DBB99', barThickness: 30
            },
            {
              label: 'Pending',
              data: this.reportsList[index].components.pending,
              fill: false, borderColor: '#d5d5d5', backgroundColor: '#d5d5d5', barThickness: 30
            },
          ]
        },
        options: {
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                max: 100,
                min: 0,
                stepSize: 10
              }
            }]
          },
          legend: { display: false },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += tooltipItem.yLabel + '%';
                return label;
              }
            }
          },
          animation: {
            duration: 1,
            'onComplete': function () {
              let chartInstance = this.chart;
              let ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              this.data.datasets.forEach(function (dataset, i) {
                let meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  const data = dataset.data[index];
                  if (data > 0) {
                    ctx.fillText(data + '%', bar._model.x, bar._model.y + 15);
                  }

                });
              });
            }
          }
        }
      });
    });
  }

  breadcrumbNavigate(url) {
    // console.log('In breadcrumbNavigate url: ', url);
    this.route.navigateByUrl(url);
  }

  printReport() {
    console.log("PrintReport: ", this.locationName + '/' + this.locationId + '/' + this.projectId);
  }

}
