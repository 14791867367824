<div style="padding-left:20px; margin-top:-20px; background-color: #EEF1F5;">
  <br>
  <ion-grid color="transparent" lines="none">
    <ion-row>
      <ion-col size="4">
        <div
          style="color:rgba(0, 0, 0, 0.555); float:left; margin-left:15px; margin-top:15px; font-weight: 500; font-size: 1.9em;">
          {{projectName}}
        </div>
      </ion-col>
      <ion-col size="8" >
        <span style="float: right;">
          <ul class="breadcrumb">
            <li *ngFor="let item of breadcrumdData let last = last;"
              [class]="last? 'breadcrumb-item breadcrumb-active': 'breadcrumb-item'"
              (click)="last? '' : breadcrumbNavigate(item.route)">{{item.name}}<i class="ion-chevron-right"></i></li>
          </ul>
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="text-center" style="margin-top:-40px">
    <ion-grid>
      <ion-row>
        <ion-col size="10">
          <div class="ion-padding" style="font-size: 1em; text-align: left; text-align: justify;" lines="none">
            {{projectDesc}}<br>
          </div>
        </ion-col>
        <ion-col size="2" *ngIf="authChecker">
          <ion-button (click)="printReport()" style="float: right;" mat-raised-button color="primary">
            <mat-icon>print</mat-icon> &nbsp; PRINT
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <ion-card id="reports" *ngIf="subSystemFlag">
    <ion-row>
      <ion-col size="4" class="ion-padding" style=" cursor: pointer;">
        <div class="main-cardTitle">
          <b> {{reportsList[0].subSystem}} </b>
        </div>
        <br><br>
        <div class="detail">
          <p>Overall Performance
          </p>
        </div>
        <br>
        <div>
          <ion-item class="text-center progressItem ion-no-padding" lines="none">
            <div class="success" [style.width]="(reportsList[0].pass==0? '0%' : (reportsList[0].pass<10? '10%' : reportsList[0].pass+'%'))">
              <p class="progressNumber"> {{reportsList[0].pass}}% </p>
            </div>
            <div class="fail" [style.width]="(reportsList[0].fail==0? '0%' : (reportsList[0].fail<10? '10%' : reportsList[0].fail+'%'))">
              <p class="progressNumber"> {{reportsList[0].fail}}% </p>
            </div>
            <div class="pending" [style.width]="(reportsList[0].pending==0? '0%' : (reportsList[0].pending<10? '10%' : reportsList[0].pending+'%'))">
              <p class="progressNumber"> {{reportsList[0].pending}}% </p>
            </div>
          </ion-item>
        </div>
        <br><br>
        <div><span style="font-size: 1.7em;"> Total Sub-Systems:</span>
          &nbsp;&nbsp;<span style="font-size: 2.2em; margin-top:3px;"> {{reportsList[0].components.componentsName.length}} </span>
        </div>
      </ion-col>

      <ion-col size="8">
        <br><canvas height="80" #lineCanvas class="lineChart-bar"></canvas>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6"></ion-col>
      <ion-col size="6">
        <div class="text-center">
          <ion-item class="progressDetail" lines="none">
            <div style="background-color: #1DBB99; height:20px; width:20px"></div><span>&nbsp; &nbsp;
              Pass</span>&nbsp; &nbsp;&nbsp; &nbsp;
            <div style="background-color: #DC5E5E; height:20px; width:20px"></div><span>&nbsp; &nbsp;
              Fail</span>&nbsp; &nbsp;&nbsp;&nbsp;
            <div style="background-color: #D5D5D5; height:20px; width:20px"></div><span>&nbsp; &nbsp; Pending</span>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-card>

  <ion-grid color="transparent" lines="none" *ngIf="subSystemFlag">
    <ion-row>
      <ion-col size="4">
        <div
          style="color:rgba(0, 0, 0, 0.555); float:left; margin-left:15px; margin-top:15px; font-weight: 500; font-size: 1.9em;">
          Sub-Systems
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>


  <ion-card id="reports" *ngFor="let item of reportsList | slice:1; let index = index">
    <ion-row>
      <ion-col size="4" class="ion-padding" style=" cursor: pointer;">
        <div class="main-cardTitle">
          <b> {{item.subSystem}} </b>
        </div>
        <br><br>
        <div class="detail">
          <p>Overall Performance
          </p>
        </div>
        <br>
        <div>
          <ion-item class="text-center progressItem ion-no-padding" lines="none">
            <div class="success" [style.width]="(item.pass==0? '0%' : (item.pass<10? '10%' : item.pass+'%'))">
              <p class="progressNumber"> {{item.pass}}% </p>
            </div>
            <div class="fail" [style.width]="(item.fail==0? '0%' : (item.fail<10? '10%' : item.fail+'%'))">
              <p class="progressNumber"> {{item.fail}}% </p>
            </div>
            <div class="pending" [style.width]="(item.pending==0? '0%' : (item.pending<10? '10%' : item.pending+'%'))">
              <p class="progressNumber"> {{item.pending}}% </p>
            </div>
          </ion-item>
        </div>
        <br><br>
        <div><span style="font-size: 1.7em;"> Total Components:</span>
          &nbsp;&nbsp;<span style="font-size: 2.2em; margin-top:3px;"> {{item.components.componentsName.length}} </span>
        </div>
      </ion-col>

      <ion-col size="8">
        <br><canvas height="80" #lineCanvas class="lineChart-bar"></canvas>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6"></ion-col>
      <ion-col size="6">
        <div class="text-center">
          <ion-item class="progressDetail" lines="none">
            <div style="background-color: #1DBB99; height:20px; width:20px"></div><span>&nbsp; &nbsp;
              Pass</span>&nbsp; &nbsp;&nbsp; &nbsp;
            <div style="background-color: #DC5E5E; height:20px; width:20px"></div><span>&nbsp; &nbsp;
              Fail</span>&nbsp; &nbsp;&nbsp;&nbsp;
            <div style="background-color: #D5D5D5; height:20px; width:20px"></div><span>&nbsp; &nbsp; Pending</span>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-card>

  <div *ngIf="pageLoader">
    <img src="../assets/preloader.gif" class="loader">
  </div>

</div>