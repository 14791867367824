import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menuItems = [
    {
      menuName: 'Location',
      menuIcon: 'mdi mdi-monitor-dashboard',
      routerLink: 'home/location'
    },
    {
      menuName: 'Profile',
      menuIcon: 'fas fa-user-graduate',
      subMenu: [
        {
          menuName: 'View Profile',
          routerLink: ''
        },
        {
          menuName: 'Edit Profile',
          routerLink: ''
        },

      ]
    },
    {
      menuName: 'Support',
      menuIcon: 'far fa-question-circle',
    }
  ];
  selectedMenuItem;
  menuOpen = false;
  currentUser: string;
  webView = false;
  userImage: string;
  constructor(
    public menu: MenuController,
    private route: Router) {

    this.currentUser = 'username';
  }
  ngOnInit() {
    if (window.matchMedia('(min-width: 992px)').matches) {
      this.webView = true;
    } else {
      this.webView = false;
    }
  }


  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  toggle() {
    this.selectedMenuItem = '';
    if (this.menuOpen) {
      this.menuOpen = false;
    }
    else {
      this.menuOpen = true;
    }
    this.menu.toggle();
  }

  closeMenu() {
    this.menu.close();
  }

  openMenuItem(path: string) {
    this.route.navigateByUrl(path);
    this.closeMenu();
  }

  goToPage(routerLink) {
    this.route.navigate([routerLink]);
    this.closeMenu();
  }
}
