import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { ProjectsReportsComponent } from './projects-reports/projects-reports.component';
import { MatIconModule } from '@angular/material/icon';




@NgModule({
  declarations: [MenuComponent, ProjectsReportsComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatIconModule
  ],
  exports: [MenuComponent,ProjectsReportsComponent]
})
export class SharedModule { }
