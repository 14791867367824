import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'location',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./page/location/location.module').then(m => m.LocationPageModule)
  },
  {
    path: 'project/:locationId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./page/project/project.module').then(m => m.ProjectPageModule)
  },
  {
    path: 'test-details/:projectId/:subSystem/:component/:testId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./page/test-details/test-details.module').then(m => m.TestDetailsPageModule)
  },
  {
    path: 'project-details/:projectId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./page/project-details/project-details.module').then(m => m.ProjectDetailsPageModule)
  },
  {
    path: 'reports/:locationName/:locationId/:projectId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./page/reports/reports.module').then(m => m.ReportsPageModule)
  },
  {
    path: 'reports-pdf/:locationName/:locationId/:projectId',
    loadChildren: () => import('./page/reports-pdf/reports-pdf.module').then( m => m.ReportsPdfPageModule)
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
