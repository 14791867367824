// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// https://gqimam44l3.execute-api.us-west-2.amazonaws.com/dev/api/  
const rootUrl = 'https://pgreqhz4hi.execute-api.us-west-2.amazonaws.com/dev/api/';

export const environment = {
  stage: 'dev',
  newUserLink: 'https://test-automation-dev.auth.us-west-2.amazoncognito.com/login?client_id=6edap6rrmc631t3rctlqi18cov&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https://test-automation-dev.auth.us-west-2.amazoncognito.com',
  production: false,

  login: rootUrl + 'login',
  location: rootUrl + 'locations',
  project: rootUrl + 'projects',
  components: rootUrl + 'projects/components/',
  testList: rootUrl + 'projects/tests/',

  importMtp: rootUrl + 'projects/importFile',
  importMtpCheck: rootUrl + 'projects/importMtpStep1',
  importMtpStatus: rootUrl + 'projects/jobStatus/',
  exportMtp: rootUrl + 'projects/exportMtp',

  notification: rootUrl + 'projects/getNotifications?projectId=',

  addNotes: rootUrl + 'projects/createNotes',
  updateNotes: rootUrl + 'projects/updateNotes',
  deleteNotes: rootUrl + 'projects/deleteNotes',
  getNotes: rootUrl + 'projects/getNotes',

  reports: rootUrl + 'projects/generateReports/',
  reportsPdf: rootUrl + 'projects/generateReportsWithoutAuth/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
